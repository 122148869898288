import { graphql } from "gatsby"
import AllRecipe from "./allRecipe"

const DietaryType = AllRecipe

export const query = graphql`
  query dietaryTypesByName($title: String) {
    recipes: allDatoCmsRecipe(
      filter: { dietaryTypes: { elemMatch: { name: { eq: $title } } } }
    ) {
      nodes {
        ...Recipe
      }
    }
  }
`

export default DietaryType
